import request from '../request'

export default {
  getInfraestructuras: function (data) {
    return request.get('infraestructura', data)
  },
  addInfraestructura: function (data) {
    return request.post('infraestructura', data)
  },
  updateInfraestructura: function (data) {
    return request.put('infraestructura', data)
  },
  getTipos: function () {
    return request.get('infraestructura_tipo')
  },
  getCategorias: function () {
    return request.get('infraestructura_categoria')
  },
  getMunicipios: function () {
    return request.get('municipio')
  },
  getProvincias: function () {
    return request.get('INE_provincia')
  },
  getOrganismos: function () {
    return request.get('organismo')
  }

}
