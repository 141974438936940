<template>
  <v-dialog v-model="show" persistent max-width="500px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ accionDialog }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col style="height: fit-content;">
                <v-text-field v-model="dataForm.medio" class="compact" label="Medio*" :rules="[globalRules.required]" outlined dense />
              </v-col>
              <v-col>
                <v-text-field label="Nombre*" :rules="[globalRules.required]" v-model="dataForm.nombre" class="compact" outlined dense />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete v-model="dataForm.categoriaSelected" class="compact" label="Categoría*" :items="categorias" item-text="CATEGORIA" item-value="CATEGORIA" return-object :rules="[globalRules.required]" outlined dense clearable />
              </v-col>
              <v-col>
                <v-autocomplete v-model="dataForm.tipoSelected" class="compact" label="Tipo*" :items="tipos" item-text="TIPO" item-value="TIPO" return-object :rules="[globalRules.required]" outlined dense clearable />
              </v-col>
            </v-row>

            <v-row />
            <v-row v-show="dataForm.tipoSelected && dataForm.tipoSelected.CATEGORIA === 'Aéreo'">
              <v-col>
                <v-text-field label="Velocidad máxima (km/h)*" hide-spin-buttons :rules="[globalRules.required]" v-model="dataForm.velocidadMaxima" class="compact" type="number" maxlength="9" outlined dense />
              </v-col>
              <v-col>
                <v-text-field label="Modelo*" :rules="[globalRules.required]" v-model="dataForm.modelo" class="compact" outlined dense />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field label="Teléfono interno*" hide-spin-buttons :rules="[globalRules.required]" type="number" maxlength="9" v-model="dataForm.tlfInterno" class="compact" outlined dense />
              </v-col>

              <v-col>
                <v-text-field label="Teléfono externo*" hide-spin-buttons :rules="[globalRules.required]" type="number" maxlength="9" v-model="dataForm.tlfExterno" class="compact" outlined dense />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete label="Organismo*" :rules="[globalRules.required]" :items="organismos" item-text="ORGANISMO" item-value="ORGANISMO" v-model="dataForm.organismosSelected" class="compact" return-object outlined dense clearable />
              </v-col>

              <v-col>
                <v-text-field label="Guardias*" :rules="[globalRules.required]" v-model="dataForm.guardias" class="compact" outlined dense />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete label="Base*" :items="bases" item-text="NOMBRE" :rules="[globalRules.required]" item-value="NOMBRE" v-model="dataForm.baseSelected" class="compact" return-object outlined dense clearable />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-menu ref="menuHoraEntrada" v-model="showHoraEntrada" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="dataForm.horaEntrada" class="compact" label="Hora entrada*" :rules="[globalRules.required]" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" outlined dense />
                  </template>
                  <v-time-picker v-model="dataForm.horaEntrada" v-show="showHoraEntrada" format="24hr">
                    <v-spacer />
                    <v-btn text color="primary" @click="showHoraEntrada = false">Cancelar</v-btn>
                    <v-btn text color="primary" @click="$refs.menuHoraEntrada.save(dataForm.horaEntrada)">OK</v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>

              <v-col>
                <v-menu ref="menuHoraSalida" v-model="showHoraSalida" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="dataForm.horaSalida" label="Hora salida*" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" outlined dense :rules="[globalRules.required]" />
                  </template>
                  <v-time-picker v-model="dataForm.horaSalida" v-show="showHoraSalida" format="24hr">
                    <v-spacer />
                    <v-btn text color="primary" @click="showHoraSalida = false">Cancelar</v-btn>
                    <v-btn text color="primary" @click="$refs.menuHoraSalida.save(dataForm.horaSalida)">OK</v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters } from 'vuex'
import constants from '../../helpers/constants'

export default {
  props: {
    show: Boolean,
    isEdit: Boolean,
    editedMedio: Object
  },

  data: () => ({
    isValid: false,
    showHoraSalida: false,
    showHoraEntrada: false,

    dataForm: {
      medio: null,
      velocidadMaxima: null,
      modelo: null,
      nombre: null,
      tlfInterno: null,
      tlfExterno: null,
      guardias: null,
      horaEntrada: null,
      horaSalida: null,
      categoriaSelected: null,
      tipoSelected: null,
      organismosSelected: null,
      baseSelected: null
    },

    globalRules: {
      required: value => !!value || 'Este campo es obligatorio'
    }
  }),

  computed: {
    ...mapGetters('medio', [
      'tiposMedio', 'categoriasMedio'
    ]),
    ...mapGetters('organismo', [
      'organismos'
    ]),

    aereoSelected () {
      return this.dataForm.categoriaSelected?.CATEGORIA === 'Aéreo'
    },

    accionDialog () {
      return this.isEdit ? 'Editar' : 'Nuevo'
    },

    tipos () {
      let tipos = JSON.parse(JSON.stringify(this.tiposMedio))
      if (this.dataForm.categoriaSelected) {
        tipos = tipos.filter(x => x.CATEGORIA === this.dataForm.categoriaSelected.CATEGORIA)
      }
      return tipos.sort((a, b) => constants.collator.compare(a.TIPO, b.TIPO))
    },

    categorias () {
      let categorias = JSON.parse(JSON.stringify(this.categoriasMedio))
      return categorias.sort((a, b) => constants.collator.compare(a.TIPO, b.TIPO))
    },

    bases () {
      let bases = JSON.parse(JSON.stringify(this.$store.getters['medio/bases']))
      return bases.sort((a, b) => constants.collator.compare(a.NOMBRE, b.NOMBRE))
    },

    organismos () {
      let organismos = JSON.parse(JSON.stringify(this.$store.getters['organismo/organismos']))
      return organismos.sort((a, b) => constants.collator.compare(a.ORGANISMO, b.ORGANISMO))
    }
  },

  watch: {
    show () {
      if (this.show && this.isEdit) {
        this.dataForm.medio = this.editedMedio.MEDIO
        this.dataForm.categoriaSelected = this.categorias.find(x => x.CATEGORIA === this.editedMedio.CATEGORIA)
        this.dataForm.tipoSelected = this.tiposMedio.find(x => x.TIPO === this.editedMedio.TIPO)
        this.dataForm.velocidadMaxima = this.editedMedio.VELOCIDAD_MAX
        this.dataForm.modelo = this.editedMedio.MODELO
        this.dataForm.nombre = this.editedMedio.NOMBRE
        this.dataForm.tlfInterno = this.editedMedio.TELEFONO_INTERNO
        this.dataForm.tlfExterno = this.editedMedio.TELEFONO_EXTERNO
        this.dataForm.organismosSelected = this.organismos.find(x => x.ORGANISMO === this.editedMedio.ORGANISMO)
        this.dataForm.guardias = this.editedMedio.GUARDIAS
        this.dataForm.baseSelected = this.bases.find(x => x.NOMBRE === this.editedMedio.BASE)
        this.dataForm.horaEntrada = this.editedMedio.HORA_ENTRADA
        this.dataForm.horaSalida = this.editedMedio.HORA_SALIDA
      }
    },
    dataForm: {
      handler () {
        this.checkFormValidity()
      },
      deep: true
    },
    'dataForm.categoriaSelected' () {
      if (!this.dataForm.categoriaSelected || this.dataForm.categoriaSelected?.CATEGORIA !== this.dataForm.tipoSelected?.CATEGORIA) {
        this.dataForm.tipoSelected = null
        this.dataForm.velocidadMaxima = null
        this.dataForm.modelo = null
      }
    },
    'dataForm.tipoSelected' () {
      if (this.dataForm.tipoSelected) {
        this.dataForm.categoriaSelected = this.categorias.find(x => x.CATEGORIA === this.dataForm.tipoSelected.CATEGORIA)
      }
    }
  },

  methods: {
    checkFormValidity () {
      this.isValid =
        !!this.dataForm.medio &&
        !!this.dataForm.tipoSelected &&
        !!this.dataForm.nombre &&
        !!this.dataForm.tlfInterno &&
        !!this.dataForm.tlfExterno &&
        !!this.dataForm.organismosSelected &&
        !!this.dataForm.guardias &&
        !!this.dataForm.baseSelected &&
        !!this.dataForm.horaEntrada &&
        !!this.dataForm.horaSalida &&
        !!this.dataForm.categoriaSelected &&
        (this.aereoSelected ? !!this.dataForm.velocidadMaxima : true) &&
        (this.aereoSelected ? !!this.dataForm.modelo : true)
    },

    cancelar () {
      this.$refs.form.reset()
      this.$emit('cancelar')
    },

    aceptar () {
      let medio = this.isEdit ? JSON.parse(JSON.stringify(this.editedMedio)) : {}

      if (this.dataForm.tipoSelected.CATEGORIA === 'Aéreo') {
        medio.VELOCIDAD_MAX = this.dataForm.velocidadMaxima
        medio.MODELO = this.dataForm.modelo
      } else {
        medio.VELOCIDAD_MAX = null
        medio.MODELO = null
      }

      medio.MEDIO = this.dataForm.medio
      medio.TIPO = this.dataForm.tipoSelected.TIPO
      medio.CATEGORIA = this.dataForm.tipoSelected.CATEGORIA
      medio.ID_MEDIO_TIPO = this.dataForm.tipoSelected.ID_MEDIO_TIPO
      medio.NOMBRE = this.dataForm.nombre
      medio.TELEFONO_INTERNO = this.dataForm.tlfInterno
      medio.TELEFONO_EXTERNO = this.dataForm.tlfExterno
      medio.ID_ORGANISMO = this.dataForm.organismosSelected && this.dataForm.organismosSelected !== '' ? this.dataForm.organismosSelected.ID_ORGANISMO : 'NULL'
      medio.GUARDIAS = this.dataForm.guardias
      medio.BASE = this.dataForm.baseSelected.NOMBRE === '' ? '' : this.dataForm.baseSelected.NOMBRE
      medio.ID_BASE = this.dataForm.baseSelected.ID_BASE === '' ? 'NULL' : this.dataForm.baseSelected.ID_BASE
      medio.HORA_ENTRADA = this.dataForm.horaEntrada
      medio.HORA_SALIDA = this.dataForm.horaSalida

      if (this.isEdit) {
        this.$emit('editMedio', medio)
      } else {
        medio.ID_MEDIO = this.$uuid.createUUID()
        medio.ESTADO = 0
        this.$emit('addMedio', medio)
      }

      this.$refs.form.reset()
    }
  }
}
</script>

<style scoped>
.compact ::v-deep(.v-text-field__details){
  height: fit-content;
  margin: 0;
}
</style>
