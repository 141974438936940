import api from '@/api'
import Vue from 'vue'

let Q = require('q')

const state = {
  infraestructuras: [],
  tipos: [],
  categorias: [],
  municipios: [],
  provincias: [],
  organismos: []
}

const getters = {
  infraestructuras: (state) => {
    return state.infraestructuras
  },
  tipos: (state) => {
    return state.tipos
  },
  categorias: (state) => {
    return state.categorias
  },
  municipios: (state) => {
    return state.municipios
  },
  provincias: (state) => {
    return state.provincias
  },
  organismos: (state) => {
    return state.organismos
  }
}

const mutations = {
  SET_INFRAESTRUCTURAS (state, infraestructuras) {
    state.infraestructuras = infraestructuras
  },
  SOCKET_addInfraestructura (state, infraestructura) {
    state.infraestructuras.push(infraestructura)
    this.notify('Infraestructura creada', `Se ha creado la infraestructura ${infraestructura.NOMBRE}`)
  },
  SOCKET_updateInfraestructura (state, data) {
    let positionInfraestructura = state.infraestructuras.findIndex(x => x.ID_INFRAESTRUCTURA.equalsIgnoreCase(data.ID_INFRAESTRUCTURA))

    if (positionInfraestructura !== -1) {
      state.infraestructuras[positionInfraestructura] = data
      this.notify('Infraestructura actualizada', `Se ha actualizado la infraestructura ${data.NOMBRE}`)
    }
    Vue.set(state.infraestructuras, positionInfraestructura, data)
  },

  SET_TIPOS (state, tipos) {
    state.tipos = tipos
  },

  SET_CATEGORIAS (state, categorias) {
    state.categorias = categorias
  },

  SET_MUNICIPIOS (state, municipios) {
    state.municipios = municipios
  },

  SET_PROVINCIAS (state, provincias) {
    state.provincias = provincias
  },

  SET_ORGANISMOS (state, organismos) {
    state.organismos = organismos
  }
}

const actions = {
  async fetchInfraestructuras ({ commit }) {
    let deferred = Q.defer()
    try {
      const response = await api.infraestructura.getInfraestructuras()
      commit('SET_INFRAESTRUCTURAS', response.data)
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },
  async addInfraestructura ({ commit }, data) {
    let deferred = Q.defer()
    try {
      const response = await api.infraestructura.addInfraestructura(data)
      if (response.status === 200) {
        commit('SOCKET_addInfraestructura', data)
        this._vm.$socket.emit('addInfraestructura', data)
      }
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },
  async updateInfraestructura ({ commit }, data) {
    let deferred = Q.defer()
    try {
      const response = await api.infraestructura.updateInfraestructura(data)
      if (response.status === 200) {
        commit('SOCKET_updateInfraestructura', data)
        this._vm.$socket.emit('updateInfraestructura', data)
      }
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },
  async fetchTipos ({ commit }) {
    let deferred = Q.defer()
    try {
      const response = await api.infraestructura.getTipos()
      commit('SET_TIPOS', response.data)
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },
  async fetchCategorias ({ commit }) {
    let deferred = Q.defer()
    try {
      const response = await api.infraestructura.getCategorias()
      commit('SET_CATEGORIAS', response.data)
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },
  async fetchProvincias ({ commit }) {
    let deferred = Q.defer()
    try {
      const response = await api.infraestructura.getProvincias()
      commit('SET_PROVINCIAS', response.data)
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },
  async fetchMunicipios ({ commit }) {
    let deferred = Q.defer()
    try {
      const response = await api.infraestructura.getMunicipios()
      commit('SET_MUNICIPIOS', response.data)
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },
  async fetchOrganismos ({ commit }) {
    let deferred = Q.defer()
    try {
      const response = await api.infraestructura.getOrganismos()
      commit('SET_ORGANISMOS', response.data)
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },
  async disableInfraestructura ({ commit }, data) {
    let deferred = Q.defer()
    try {
      let dataCopy = JSON.parse(JSON.stringify(data))
      dataCopy.BORRADO = true
      const response = await api.infraestructura.updateInfraestructura(dataCopy)
      if (response.status === 200) {
        commit('SOCKET_updateInfraestructura', dataCopy)
        this._vm.$socket.emit('updateInfraestructura', dataCopy)
      }
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },
  async enableInfraestructura ({ commit }, data) {
    let deferred = Q.defer()
    try {
      let dataCopy = JSON.parse(JSON.stringify(data))
      dataCopy.BORRADO = false
      const response = await api.infraestructura.updateInfraestructura(dataCopy)
      if (response.status === 200) {
        commit('SOCKET_updateInfraestructura', dataCopy)
        this._vm.$socket.emit('updateInfraestructura', dataCopy)
      }
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
