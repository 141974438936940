<template>
  <div>
    <v-card elevation="5">
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Sectores</v-toolbar-title>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn fab small v-bind="attrs" v-on="on" color="secondary" bottom right absolute :disabled="isIncendioFinalizado || disabledByRol" @click="showGestionSectores = true">
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar sectores</span>
        </v-tooltip>
      </v-toolbar>

      <v-card-text
        style="flex-grow: 1;
  overflow: auto;"
      >
        <v-btn-toggle v-model="sectoresSelected" color="primary" rounded multiple>
          <v-btn :key="i" v-for="(sector,i) in sectoresIncendio" small>{{ sector.SECTOR }}</v-btn>
        </v-btn-toggle>
      </v-card-text>
    </v-card>

    <vx-dialog-gestion-sectores :show="showGestionSectores" :idIncendio="idIncendio" @close="showGestionSectores = false" /> <!-- @cancelar="showGestionSectores = false" @aceptar="saveSectores" -->
  </div>
</template>

<script>
import DialogGestionSectores from './DialogGestionSectores'

export default {
  components: {
    'vx-dialog-gestion-sectores': DialogGestionSectores
  },

  props: {
    idIncendio: String
  },

  data: () => ({
    sectoresSelected: [],
    showGestionSectores: false
  }),

  computed: {
    incendio () {
      let incendio = JSON.parse(JSON.stringify(this.$store.getters['incendio/getIncendioByIDAll'](this.idIncendio)))
      return incendio || {}
    },
    isIncendioFinalizado () {
      return this.$store.getters['incendio/isIncendioFinalizado'](this.idIncendio)
    },

    sectoresIncendio () {
      let sectores = []

      if (this.incendio) {
        sectores = this.incendio.SECTORES
      }

      return sectores
    },
    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
    }
  },

  watch: {
    idIncendio () { // Al abrir, seleccionar todos
      this.sectoresSelected = []
      if (this.idIncendio) {
        for (let i = 0; i < this.sectoresIncendio.length; i++) {
          this.sectoresSelected.push(i)
        }
      }
    },

    sectoresSelected () {
      this.$emit('changeSectoresSelected', this.sectoresSelected)
    }
  },

  methods: {
    saveSectores (sectores) {
      this.showGestionSectores = false
      this.$store.dispatch('incendio/changeSectoresIncendio', {
        ID_INCENDIO: this.idIncendio,
        SECTORES: sectores
      })
    }
  }
}
</script>

<style scoped>
</style>
