<template>
  <v-navigation-drawer
    ref="drawer"
    v-model="show"
    :width="navigation.width"
    color="#AAAAAACC"
    absolute
    hide-overlay
    stateless
    style="z-index: 2"
  >
    <v-toolbar color="primaryLight" dark dense>
      <v-btn dark icon @click="exit">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{
          incendio ? (incendio.NOMBRE ? incendio.NOMBRE : incendio.LOCALIDAD) : ""
        }}
      </v-toolbar-title>
      <v-spacer />

      <!-- Track medios -->
      <v-tooltip bottom v-for="button in buttons" :key="button.id">
        <template #activator="{ on, attrs }">
          <v-btn
            v-show="button.visible"
            v-bind="attrs"
            v-on="on"
            fab
            small
            color="secondary"
            bottom
            right
            class="me-3"
            @click="clickBtn(button.id)"
          >
            <v-icon color="primary">{{ button.icon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ button.label }}</span>
      </v-tooltip>
      <!-- <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn fab small v-bind="attrs" v-on="on" v-show = "noEstaFinalizado" color="secondary" bottom right class="mr-3" @click="changeVisibilityNivelIncendio">
            <v-icon color="primary">mdi-list-status</v-icon>
          </v-btn>
        </template>
        <span>Cambiar</span>
      </v-tooltip> -->

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            small
            v-bind="attrs"
            v-on="on"
            color="secondary"
            bottom
            right
            class="me-3"
            :disabled="isButtonDisabled"
            @click="generateInformePosiciones"
          >
            <v-icon color="primary">mdi-file-chart-outline</v-icon>
          </v-btn>
        </template>
        <span>Informe posiciones</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            small
            v-bind="attrs"
            v-on="on"
            color="secondary"
            bottom
            right
            class="me-3"
            @click="generatePDFIncendio"
          >
            <v-icon color="primary">mdi-file-pdf-box</v-icon>
          </v-btn>
        </template>
        <span>Resumen del incendio</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            small
            v-bind="attrs"
            v-on="on"
            color="secondary"
            bottom
            right
            class="me-3"
            @click="showDialogImagen = true"
          >
            <v-icon color="primary">mdi-camera</v-icon>
          </v-btn>
        </template>
        <span>Subir imágenes</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            small
            v-bind="attrs"
            v-on="on"
            color="secondary"
            bottom
            right
            @click="showDialogDeleteIncendio = true"
          >
            <v-icon color="primary">mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Eliminar incendio</span>
      </v-tooltip>
    </v-toolbar>

    <v-container fluid style="height: calc(100vh - 112px); overflow-y: auto">
      <v-snackbar v-model="showSnackBar" color="error" bottom :timeout="5000">
        {{ msgSnackBar }}
      </v-snackbar>
      <v-snackbar v-model="isLoadingTrack" bottom :timeout="-1">
        Descargando datos del track
        <template #action="{ attrs }">
          <v-progress-circular v-on="attrs" indeterminate />
        </template>
      </v-snackbar>
      <vx-datos-incendio class="mt-4" :idIncendio="idIncendioSelected" />
      <vx-datos-sectores
        class="mt-4"
        :idIncendio="idIncendioSelected"
        @changeSectoresSelected="changeSectoresSelected"
      />
      <vx-datos-medios
        class="mt-4"
        :idIncendio="idIncendioSelected"
        :sectoresSelected="sectoresSelected"
      />
      <vx-datos-planes-operaciones
        class="mt-4"
        :idIncendio="idIncendioSelected"
      />
      <vx-datos-perimetros
        class="mt-4"
        :idIncendio="idIncendioSelected"
      /> <!--desde aquí visualizamos la nueva clase MenuPerimetro-->
      <vx-imagen-satelite class="mt-4" :idIncendio="idIncendioSelected" />
      <vx-dialog-track-fechas
        :show="showDialogTrackAllMedios"
        :idIncendio="idIncendioSelected"
        @aceptar="obtainAllTrackMedios"
        @cancelar="cancelTrackMedio"
      />
      <vx-menu-alineaciones-campbell class="mt-4" :idIncendio="idIncendioSelected" />
      <vx-dialog-add-imagenes class="mt-4" :idIncendio="idIncendioSelected" :show="showDialogImagen" @cancelar="cancelAddImagenes" />
      <vx-dialog-delete-incendio class="mt-4" :incendio="incendio" :show="showDialogDeleteIncendio" @eliminar="deletedIncendio" @cancelar="cancelDeleteIncendio" />
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import DatosIncendio from './DatosIncendio.vue'
import DatosPlanesOperaciones from './DatosPlanesOperaciones.vue'
import DatosSectores from './DatosSectores.vue'
import DatosMedios from './DatosMedios.vue'
import constants from '@/helpers/constants'
import ImagenSatelite from './ImagenSatelite.vue'
import DialogTrackAllMedios from './DialogTrackAllMedios.vue'
import MenuAlineacionesCampbell from './MenuAlineacionesCampbell.vue'
import MenuPerimetro from './MenuPerimetro.vue' // importamos la nueva clase
import DialogAddImagenes from './DialogAddImagenes.vue'
import DialogDeleteIncendio from './DialogDeleteIncendio.vue'

// import IncendioGestion from './IncendioGestion'

import * as ArcGIS from '../../../helpers/ArcGIS'
import api from '@/api'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import medio from '@/api/medio'
import { thenResolve } from 'q'
import * as PDFHelper from '../../../helpers/PDFHelper'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  components: {
    'vx-datos-incendio': DatosIncendio,
    'vx-datos-planes-operaciones': DatosPlanesOperaciones,
    'vx-datos-sectores': DatosSectores,
    'vx-datos-perimetros': MenuPerimetro, // añadimos su componente
    'vx-datos-medios': DatosMedios,
    'vx-imagen-satelite': ImagenSatelite,
    'vx-dialog-track-fechas': DialogTrackAllMedios,
    'vx-menu-alineaciones-campbell': MenuAlineacionesCampbell,
    'vx-dialog-add-imagenes': DialogAddImagenes,
    'vx-dialog-delete-incendio': DialogDeleteIncendio
    // 'vx-incendio-gestion': IncendioGestion,
  },

  props: {
    show: Boolean,
    showIncendiosCards: Boolean,
    idIncendioSelected: String
  },

  data: () => ({
    // idIncendio: null,
    isButtonDisabled: false,
    showDialogDeleteIncendio: false,
    showDialogImagen: false,
    isLoadingTrack: false,
    msgSnackBar: '',
    showSnackBar: false,
    sectoresSelected: [],
    mediostracked: false,
    buttons: [
      {
        id: 'showTrack',
        icon: 'mdi-map-marker',
        label: 'Track de los medios asociados',
        visible: true
      },
      {
        id: 'hideTrack',
        icon: 'mdi-map-marker-off',
        label: 'Ocultar track',
        visible: false
      }
    ],
    dispositivosAllIncendios: new Map(),
    arrayDispositivosIncendio: [],
    showDialogTrackAllMedios: false,

    navigation: {
      width: 670,
      borderSize: 3,
      minWidth: 650
    }
  }),
  watch: {
    idIncendioSelected (idNuevoIncendio) {
      // this.idIncendio = idNuevoIncendio
      // console.log('estoy cambiando' + this.idNuevoIncendio)
      if (idNuevoIncendio != null) {
        this.showSnackBar = false
        this.showSnackBar = ''
        this.arrayDispositivosIncendio = this.dispositivosAllIncendios.has(
          idNuevoIncendio
        )
          ? this.dispositivosAllIncendios.get(idNuevoIncendio)
          : []
        // Determinamos la visibilidad del boton de hide traks
        this.visibilidadBotones()
      }
    }
  },

  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    incendio () {
      // console.log('soy el id' + this.idIncendioSelected)
      return this.$store.getters['incendio/getIncendioByIDAll'](
        this.idIncendioSelected
      )
    },
    deteccion () {
      let deteccion = this.incendio
        ? this.$store.getters['incendio/getTipoDeteccionById'](
          this.incendio.ID_TIPO_DETECCION
        )
        : null
      return deteccion || {}
    },
    medios () {
      return this.$store.getters['medio/medios']
    },
    noEstaFinalizado () {
      return this.incendio == null
        ? true
        : this.incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO !==
            'Finalizado'
    }
  },

  methods: {
    // Funciones auxiliares
    getFecha (id, medio) {
      switch (id) {
        case 0:
          return medio.FECHA_AVISO
        case 1:
          return medio.FECHA_LLEGADA
        case 2:
          return medio.FECHA_SALIDA
        case 3:
          return medio.FECHA_LLEGADA_BASE
        default:
          return null
      }
    },
    formatFechaFin (fechaFin) {
      if (fechaFin == null) return this.$date.now()
      return this.$date.parseDate(fechaFin)
    },
    formatFechaInicio (fechaInicio) {
      return this.$date.parseDate(fechaInicio)
    },

    visibilidadBotones () {
      let visibilidad = this.arrayDispositivosIncendio.length > 0

      this.buttons[0].visible = !visibilidad
      this.buttons[1].visible = visibilidad
    },

    clickBtn (btn) {
      if (btn === 'showTrack') {
        this.showDialogTrackAllMedios = true
        // this.obtainAllTrackMedios()
      } else if (btn === 'hideTrack') {
        this.hideTrack()
      }
    },
    changeSectoresSelected (sectoresSelected) {
      this.sectoresSelected = sectoresSelected
    },

    exit () {
      this.$el.scrollTop = 0
      this.$emit('exit')
    },

    // Funciones para localizar los medios
    async obtainAllTrackMedios (fechaInicio, fechaFin) {
      this.showDialogTrackAllMedios = false
      let incendio = this.incendio
      let idIncendio = incendio.ID_INCENDIO
      this.msgSnackBar = ''
      let tieneMedios = false

      this.isLoadingTrack = true
      for (let i = 0; i < incendio.SECTORES.length; i++) {
        for (let j = 0; j < incendio.SECTORES[i].MEDIOS.length; j++) {
          let medio = this.incendio.SECTORES[i].MEDIOS[j]
          tieneMedios = true
          await this.loadTrackFechas(medio, fechaInicio, fechaFin)
        }
      }
      this.isLoadingTrack = false

      if (this.arrayDispositivosIncendio) {
        this.dispositivosAllIncendios.set(
          idIncendio,
          this.arrayDispositivosIncendio
        )
      }

      // this.arrayDispositivosIncendio !== null ? this.dispositivosAllIncendios.set(idIncendio, this.arrayDispositivosIncendio) : {}
      if (!tieneMedios) {
        this.msgSnackBar = 'El incendio no tiene medios asociados.'
      }
      if (this.msgSnackBar.length !== 0) {
        this.showSnackBar = true
      }
      this.visibilidadBotones()
    },
    async loadTrackFechas (medio, fechaInicio, fechaFin) {
      let infoMedio = this.medios.find((x) => x.ID_MEDIO === medio.ID_MEDIO)

      // Si la fecha de inicio no es nula
      if (this.getFecha(fechaInicio, medio) !== null) {
        // let fechaInit = this.formatFechaInicio(this.getFecha(fechaInicio, medio))
        // let fechaEnd = this.formatFechaFin(this.getFecha(fechaFin, medio))

        let dateInit = this.getFecha(fechaInicio, medio)
          ? new Date(this.getFecha(fechaInicio, medio)).toISOString()
          : new Date().toISOString()
        let dateEnd = this.getFecha(fechaFin, medio)
          ? new Date(this.getFecha(fechaFin, medio)).toISOString()
          : new Date().toISOString()
        // Date format: 2023-03-17T11:13:00.000Z
        try {
          let idDispositivo = await api.medio.getIdDispositivo(medio.ID_MEDIO)

          if (idDispositivo.data === null) {
            this.msgSnackBar +=
                'El medio ' + infoMedio.MEDIO + ' no dispone de track.\n'
          } else {
            let response = await api.medio.getTrackFechasMedio(
              idDispositivo.data.ID_GEO_DISPOSITIVO,
              dateInit,
              dateEnd
            )
            if (response.status === 200) {
              if (response.data.length >= 2) {
                this.showTrack(response.data)
              }
            }

            if (response.status === 204) {
              this.msgSnackBar +=
                  'El medio ' + infoMedio.MEDIO + ' no dispone de track.\n'
            }
          }
        } catch (err) {
          this.$log.error(
            'Error al obtener el track' + infoMedio.ID_MEDIO,
            err
          )
          this.msgSnackBar +=
            'Error al obtener el track del dispositivo del medio ' +
            infoMedio.MEDIO +
            '\n'
        }
      } else {
        this.msgSnackBar +=
          'Fecha de inicio invalida del medio ' + infoMedio.MEDIO + '\n'
      }
    },
    // Funcion para cancelar el trackeo en el dialog
    cancelTrackMedio () {
      this.showDialogTrackAllMedios = false
    },
    showTrack (posiciones) {
      let maxTimeDiference = constants.track.timeDiferenceTrackMinutes
      let maxPositions = constants.track.maxPositions
      let positions = []
      let nameTrack = ''

      // console.log(posiciones)

      for (let i = 0; i < posiciones.length; i++) {
        if (nameTrack === '' && posiciones[i].MEDIO) {
          nameTrack = posiciones[i].MEDIO
        }
        if (i === 0 || i === posiciones.length - 1) {
          positions.push(posiciones[i])
        } else {
          let dateLastPos = this.$date.parseDate(posiciones[i - 1].FECHA)
          let date = this.$date.parseDate(posiciones[i].FECHA)
          let difMinutes = dateLastPos.diff(date, 'minutes')

          if (difMinutes <= maxTimeDiference && positions.length <= maxPositions) { // this.track.positions
            // this.track.positions.push(posiciones[i])
            positions.push(posiciones[i])
          } else {
            continue
          }
        }
      }
      /**
       * Distinguir entre dispositivo o medio
       */
      let idTrack = '' //

      ArcGIS.drawTrack(idTrack, nameTrack, positions, true)
      this.arrayDispositivosIncendio.push(idTrack)
    },
    // Ocultar los tracks
    hideTrack () {
      let idIncendio = this.incendio.ID_INCENDIO
      let dispositivosEliminar = this.dispositivosAllIncendios.get(idIncendio)
      for (let i = 0; i <= dispositivosEliminar.length; i++) {
        let dispositivo = dispositivosEliminar.pop()
        ArcGIS.hideTrack(dispositivo)
      }
      this.visibilidadBotones()
      this.dispositivosAllIncendios.delete(idIncendio)
    },

    async generateInformePosiciones () {
      this.isButtonDisabled = true

      try {
        let base64Img = await ArcGIS.captureMap()
        let base64ImgOverview = await ArcGIS.captureOverviewMap()

        let measure = await ArcGIS.measureOverview()
        let escala = Math.round(ArcGIS.getMapView().scale)

        let municipio = this.incendio.MUNICIPIO ? this.incendio.MUNICIPIO : ''
        let localidad = this.incendio.LOCALIDAD ? this.incendio.LOCALIDAD : ''
        await PDFHelper.pdfInforme(base64Img, base64ImgOverview, measure, escala, municipio, localidad)
      } catch (error) {
        console.error('Error al generar el informe:', error)
      } finally {
        this.isButtonDisabled = false
      }
    },

    generatePDFIncendio () {
      let tieneMedios = false
      let tableEstados = []
      let tableMedios = []
      let listaMedios = []

      // Creo la tabla de estados
      let estados = this.incendio.ESTADOS
      for (let i = 0; i < estados.length; i++) {
        let estado = []
        estado.push(
          this.$date.formatDate(estados[i].FECHA, 'DD/MM/YYYY HH:mm')
        )
        estado.push(estados[i].TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO)
        tableEstados.unshift(estado)
      }

      tableEstados.unshift(['Fecha', 'Estado'])
      tableMedios.push([
        'Medio',
        'Categoría',
        'Aviso',
        'Llegada',
        'Salida',
        'Base',
        'Minutos',
        'Total'
      ])

      // Ordeno la lista de medios por fecha
      for (let i = 0; i < this.incendio.SECTORES.length; i++) {
        for (let j = 0; j < this.incendio.SECTORES[i].MEDIOS.length; j++) {
          tieneMedios = true
          listaMedios.push(this.incendio.SECTORES[i].MEDIOS[j])
        }
      }

      listaMedios.sort(function (a, b) {
        let aAviso = a.FECHA_AVISO
        let bAviso = b.FECHA_AVISO
        let aLlegada = a.FECHA_LLEGADA
        let bLlegada = b.FECHA_LLEGADA
        let aSalida = a.FECHA_SALIDA
        let bSalida = b.FECHA_SALIDA
        let aBase = a.FECHA_LLEGADA_BASE
        let bBase = b.FECHA_LLEGADA_BASE

        if (aAviso === bAviso) {
          if (aSalida === bSalida) {
            if (aBase === bBase) {
              return aBase < bBase ? -1 : aBase > bBase ? 1 : 0
            } else {
              return aSalida < bSalida ? -1 : aSalida > bSalida ? 1 : 0
            }
          } else {
            return aLlegada < bLlegada ? -1 : aLlegada > bLlegada ? 1 : 0
          }
        } else {
          return aAviso < bAviso ? -1 : 1
        }
      })

      let medioTotalList = []
      for (let i = 0; i < listaMedios.length; i++) {
        let medio = listaMedios[i]
        let minutosMedio = 0
        let findMedio = this.medios.find((x) => x.ID_MEDIO === medio.ID_MEDIO)

        if (medio.FECHA_AVISO && medio.FECHA_LLEGADA_BASE) {
          let diff = 0
          let fechaInicio = new Date(medio.FECHA_AVISO).getTime()
          let fechaFin = new Date(medio.FECHA_LLEGADA_BASE).getTime()
          diff = Math.floor((fechaFin - fechaInicio) / 1000)
          let minutos = Math.floor(diff / 60)
          let segundos = diff % 60
          let aux = minutos + '.' + segundos
          minutosMedio = Number(aux)
        }

        let medioTotalIndex = medioTotalList.findIndex(
          (x) => x.MEDIO === findMedio.MEDIO
        )
        if (medioTotalIndex > -1) {
          medioTotalList[medioTotalIndex].TOTAL =
            medioTotalList[medioTotalIndex].TOTAL + minutosMedio
        } else {
          medioTotalList.push({ MEDIO: findMedio.MEDIO, TOTAL: minutosMedio })
        }
      }

      // Creo la tabla de medios

      for (let i = 0; i < listaMedios.length; i++) {
        let medioTable = []
        let medio = listaMedios[i]
        let findMedio = this.medios.find((x) => x.ID_MEDIO === medio.ID_MEDIO)

        medioTable.push(findMedio.MEDIO)
        medioTable.push(findMedio.CATEGORIA)
        medioTable.push(
          this.$date.formatDate(medio.FECHA_AVISO, 'DD/MM/YYYY HH:mm')
        )
        medioTable.push(
          this.$date.formatDate(medio.FECHA_LLEGADA, 'DD/MM/YYYY HH:mm')
        )
        medioTable.push(
          this.$date.formatDate(medio.FECHA_SALIDA, 'DD/MM/YYYY HH:mm')
        )
        medioTable.push(
          this.$date.formatDate(medio.FECHA_LLEGADA_BASE, 'DD/MM/YYYY HH:mm')
        )

        // Calculo las minutoss de actuacion si el ciclo esta cerrado
        let minutosMedio2 = 0
        if (medio.FECHA_AVISO && medio.FECHA_LLEGADA_BASE) {
          let diff2 = 0
          let fechaInicio2 = new Date(medio.FECHA_AVISO).getTime()
          let fechaFin2 = new Date(medio.FECHA_LLEGADA_BASE).getTime()
          diff2 = Math.floor((fechaFin2 - fechaInicio2) / 1000)
          let minutos2 = Math.floor(diff2 / 60)
          let segundos2 = diff2 % 60
          let aux2 = minutos2 + '.' + segundos2
          minutosMedio2 = Number(aux2)
        }
        // console.log(diff / (1000 * 60 * 60))

        // let totalMedio = 0
        medioTable.push(minutosMedio2.toFixed(2))
        medioTable.push(
          medioTotalList
            .find((x) => x.MEDIO === findMedio.MEDIO)
            .TOTAL.toFixed(2)
        )

        tableMedios.push(medioTable)
      }

      let docDefinition = {
        // pageOrientation: 'landscape',
        content: [],
        defaultStyle: {
          fontSize: 11
        }
      }

      docDefinition.content.push(constants.headerPDF)
      docDefinition.content.push(
        {
          text: [
            { text: 'Fecha/minutos informe: ', bold: true },
            this.$date.currentDate()
          ],
          margin: [0, 0, 0, 15]
        },
        {
          text: [
            {
              text: 'Datos del incendio',
              bold: true,
              fontSize: 15,
              alignment: 'center'
            }
          ],
          margin: [0, 0, 0, 15]
        },
        {
          ul: [
            {
              text: [
                { text: 'Localidad: ', bold: true },
                this.incendio.LOCALIDAD
              ]
            },
            {
              text: [
                { text: 'Coordenadas: ', bold: true },
                this.incendio.LATITUD + ', ' + this.incendio.LONGITUD
              ]
            },
            {
              text: [
                { text: 'Detección: ', bold: true },
                this.deteccion.DETECCION
              ]
            },
            {
              text: [
                { text: 'Observaciones: ', bold: true },
                this.incendio.OBSERVACIONES
              ]
            }
          ]
        },
        /* {
            columns: [
              { width: '*', text: '' },
              {
                width: 'auto',
                table: {
                  body: tableEstados
                }
              },
              { width: '*', text: '' }
            ]
          }, */
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            widths: [120, 120],
            headerRows: 1,
            body: tableEstados
          },
          margin: [120, 20, 75, 20],
          alignment: 'center'
        },
        {
          text: [
            { text: 'Medios', bold: true, fontSize: 15, alignment: 'center' }
          ],
          margin: [0, 0, 0, 15]
        } /*,
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              body: tableMedios
            },
            margin: [0, 15, 0, 20],
            alignment: 'center',
            width: '100%'
          } */
      )

      if (tieneMedios) {
        docDefinition.content.push({
          layout: 'lightHorizontalLines', // optional
          table: {
            headerRows: 1,
            body: tableMedios
          },
          margin: [0, 15, 0, 20],
          alignment: 'center',
          width: '*'
        })
      } else {
        docDefinition.content.push({
          text: [
            { text: 'No existen medios', bold: false, alignment: 'center' }
          ],
          margin: [0, 0, 0, 15]
        })
      }
      pdfMake
        .createPdf(docDefinition)
        .download(
          'Incendio_' +
            this.incendio.LOCALIDAD +
            '_' +
            this.$date.now().format('YYYY_MM_DD_hh_mm')
        )
    },

    cancelAddImagenes () {
      this.showDialogImagen = false
    },
    deletedIncendio () {
      this.showDialogDeleteIncendio = false
      this.exit()
    },
    cancelDeleteIncendio () {
      this.showDialogDeleteIncendio = false
    },

    setBorderWidth () {
      let i = this.$refs.drawer.$el.querySelector(
        '.v-navigation-drawer__border'
      )
      i.style.width = this.navigation.borderSize + 'px'
      i.style.cursor = 'ew-resize'
      i.style.backgroundColor = 'transparent'
    },
    setEvents () {
      const minSize = this.navigation.borderSize
      const el = this.$refs.drawer.$el
      const drawerBorder = el.querySelector('.v-navigation-drawer__border')
      const vm = this
      const direction = el.classList.contains('v-navigation-drawer--right')
        ? 'right'
        : 'left'

      function resize (e) {
        if (e.clientX < vm.navigation.minWidth) return
        document.body.style.cursor = 'ew-resize'
        let f =
          direction === 'right'
            ? document.body.scrollWidth - e.clientX
            : e.clientX
        el.style.width = f + 'px'
      }

      drawerBorder.addEventListener(
        'mousedown',
        (e) => {
          if (e.offsetX < minSize) {
            el.style.transition = 'initial'
            document.addEventListener('mousemove', resize, false)
          }
        },
        false
      )

      document.addEventListener(
        'mouseup',
        () => {
          el.style.transition = ''
          this.navigation.width = el.style.width
          document.body.style.cursor = ''
          document.removeEventListener('mousemove', resize, false)
        },
        false
      )
    }
  },

  mounted () {
    this.setBorderWidth()
    this.setEvents()
  }

}
</script>

<style scoped></style>
