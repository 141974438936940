<template>
  <v-card flat class="mt-4 layerItem">
    <v-card-title>
      <v-switch hide-details :label="item.title" v-model="visible" />
    </v-card-title>

    <v-card-text>
      <v-slider v-if="opacity > 0" v-model="opacity" label="Opacidad" min="10" max="100" step="10" hide-details />
    </v-card-text>

    <v-card-actions>
      <slot name="append" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import * as ArcGIS from '@/helpers/ArcGIS.js'

export default {
  props: {
    item: {
      type: Object,
      default: () => { return {} }
    }
  },

  data: () => ({
    visible: false,
    opacity: null
  }),

  watch: {
    visible () {
      if (this.visible) {
        this.$emit('setVisible')
      }

      this.setVisibilityLayer({
        id: this.item.id,
        visible: this.visible,
        item: this.item
      })
      ArcGIS.setVisibilityLayer(this.item.id, this.visible)
    },
    opacity () {
      this.setOpacityLayer({
        id: this.item.id,
        opacity: this.opacity / 100,
        item: this.item
      })
      ArcGIS.setOpacityLayer(this.item.id, this.opacity / 100)
    }
  },

  computed: {
    ...mapGetters('usuario', [
      'isCurrentUsuarioExterno'
    ])
  },

  methods: {
    ...mapActions('map', [
      'setVisibilityLayer',
      'setOpacityLayer'
    ])
  },

  created () {
    this.visible = this.item.visible

    if (this.item.opacity) {
      this.opacity = this.item.opacity * 100
    }
  }
}
</script>

<style scoped>
  .layerItem {
    background: #ebebeb;
    border-radius: 10px;
  }
</style>
