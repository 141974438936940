<template>
  <v-container id="incendiosList" :class="{'mobile': $vuetify.breakpoint.xs}">
    <v-row dense class="mb-3 button-container">
      <v-col cols="3">
        <v-btn block :color="getColor(1)" @click="filtroSeleccionado = 1">
          24h
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn block :color="getColor(2)" @click="filtroSeleccionado = 2">
          48h
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn block :color="getColor(3)" @click="filtroSeleccionado = 3">
          72h
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn block :color="getColor(4)" @click="showCalendar = true">
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="showCalendar" max-width="350px" persistent content-class="custom-calendar-dialog">
      <v-card>
        <v-card-title class="text-center">Seleccione un rango de fechas</v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-date-picker v-model="fechaSeleccionada" range @change="filtrarPorFecha" />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="showCalendar = false">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row dense v-for="(incendio, i) in incendiosOrder" :key="i">
      <v-col>
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <v-card v-bind="attrs" v-on="on" :color="incendio.NIVELES.length>0 && !incendio.NIVELES[0].FECHA_FIN ? incendio.NIVELES[0].TIPO_INCENDIO_NIVEL.COLOR: incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.COLOR" elevation="6" dark @click="selectIncendio(incendio)">
              <v-card-title class="text-h7 pt-0 pl-3">
                {{ incendio.NOMBRE ? incendio.NOMBRE : incendio.LOCALIDAD }}
                <v-spacer />

                <!-- <v-btn class="mx-0" icon v-show="incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE === 4 || incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE === 5" @click.stop.prevent="ocultarIncendio(incendio)">
                  <v-icon color="black">mdi-eye-off</v-icon> -->
                <!-- </v-btn> -->
              </v-card-title>

              <v-card-subtitle :class="{'text-right': $vuetify.breakpoint.smAndUp, 'pb-0': true}">
                {{ incendio.ESTADOS[0].FECHA | formatDate }}
                {{ incendio?.EGIF ?? '' }}
              </v-card-subtitle>
            </v-card>
          </template>
          <span>Detalles incendio</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import * as ArcGIS from '@/helpers/ArcGIS.js'

export default {
  data: () => ({
    show: false,
    showCalendar: false,
    filtroIncendios: [],
    filtroSeleccionado: 1,
    fechaSeleccionada: null
  }),

  watch: {
    filtroSeleccionado () {
      this.filterEmergencies()
    }
  },

  computed: {
    ...mapGetters([
      'incendiosAll'
    ]),

    incendiosOrder () {
      return this.orderIncendios()
    }
  },

  methods: {
    getColor (tab) {
      return tab === this.filtroSeleccionado ? 'success' : 'primary'
    },

    selectIncendio (incendio) {
      this.$emit('selectIncendio', incendio.ID_INCENDIO)
    },

    filtrarPorFecha () {
      if (this.fechaSeleccionada) {
        this.filtroSeleccionado = 4
        this.showCalendar = false
      }
    },

    orderIncendios () {
      // incendios activos
      let orderIncendios = JSON.parse(JSON.stringify(this.$store.getters['incendio/incendiosAllFiltered']))

      orderIncendios.sort((a, b) => {
        let aEstado = a.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE
        let bEstado = b.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE
        let aFecha = a.FECHA_CREACION
        let bFecha = b.FECHA_CREACION

        if (aEstado === bEstado) {
          return (aFecha > bFecha) ? -1 : (aFecha < bFecha) ? 1 : 0
        } else {
          return (aEstado < bEstado) ? -1 : 1
        }
      })

      return orderIncendios
    },
    filterEmergencies () {
      let fechaFilter = []
      if (this.filtroSeleccionado === 4 && this.fechaSeleccionada.length === 2) {
        fechaFilter = [new Date(this.fechaSeleccionada[0]), new Date(this.fechaSeleccionada[1])]
      } else {
        let fechaLimite = new Date()
        fechaLimite.setDate(fechaLimite.getDate() - this.filtroSeleccionado)
        fechaFilter = [fechaLimite, new Date()]
      }
      this.$store.dispatch('incendio/setFiltro', fechaFilter)
      ArcGIS.redrawIncendios()
    }
  },

  mounted () {
    this.filterEmergencies()
  }
}
</script>

<style scoped>
  #incendiosList {
    position: absolute;
    top: 20px;
    left: 15px;
    overflow-y: auto;
    width: 300px;
    max-height: calc(100% - 100px);
  }

  .button-container {
    position: relative;
    top: -10px;
    margin-bottom: 0 !important;
  }

  .mobile {
    top: 65px !important;
    width: 170px !important;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }
</style>
