<template>
  <div>
    <!-- Snackbar cargando -->
    <v-snackbar v-model="isLoadingTrack" bottom :timeout="-1">
      Descargando datos del track
      <template #action="{ attrs }">
        <v-progress-circular v-on="attrs" indeterminate />
      </template>
    </v-snackbar>

    <!-- Snackbar errores -->
    <v-snackbar v-model="showSnackBar" color="error" bottom :timeout="5000">
      {{ msgSnackBar }}
    </v-snackbar>

    <vx-popup :datosPopup="datosPopup" :showPopup="showPopup" @clickBtn="clickBtn" @loadTrackFechas="loadTrackFechas" />

    <vx-track-fechas :show="showFechasDialog" @cancelar="showFechasDialog = false" @aceptar="loadTrackFechas" />
    <vx-all-info-dialog :show="showAllInfoDialog" :attrs="infoAttrs" @hide="showAllInfoDialog = false;infoAttrs=[]" />
  </div>
</template>

<script>
import api from '../../../api'
import constants from '../../../helpers/constants'
import * as ArcGIS from '../../../helpers/ArcGIS'
import * as PDFHelper from '../../../helpers/PDFHelper'

import Popup from './Popup'
import TrackFechas from './TrackFechas.vue'
import AllInfoDialog from './AllInfoDialog.vue'

export default {
  components: {
    'vx-popup': Popup,
    'vx-track-fechas': TrackFechas,
    'vx-all-info-dialog': AllInfoDialog
  },

  data: () => ({
    showPopup: false,
    rawPopupData: null, // Datos que devuelve el mapa
    attributes: null,

    datosPopup: null, // Datos parseados que se le pasan al popup

    showFechasDialog: false,

    isLoadingTrack: false,
    tracksActivos: [],

    showSnackBar: false,
    msgSnackBar: '',

    showAllInfoDialog: false,
    infoAttrs: []
  }),

  computed: {
    medio () {
      return this.$store.getters['medio/getMedioByID'](this.attributes.ID_MEDIO)
    },
    medioTrack () {
      return this.$store.getters['medio/getMedioByID'](this.track.ID_MEDIO)
    },
    medioLogged () {
      return this.$store.getters['usuario/getUserLoggedInMedioByID'](this.attributes.ID_MEDIO)
    }
  },

  watch: {
    /* medioTrack () { // Hace que se repiten los tracks encima de si mismos
      if (this.isShowingTrack) {
        this.track.positions.unshift({MEDIO: {ID_MEDIO: this.medioTrack.ID_MEDIO, MEDIO: this.medioTrack.MEDIO}, LATITUD: this.medioTrack.LATITUD, LONGITUD: this.medioTrack.LONGITUD, FECHA: this.medioTrack.FECHA})
        this.showTrack(this.track.positions)
      }
    } */
  },

  methods: {
    onShowPopup (data) {
      this.rawPopupData = data
      this.datosPopup = null
      if (data) {
        this.attributes = data.attributes
        this.generarDatosMaptip()

        if (!this.attributes.notShowMaptip) {
          this.showPopup = true
        }
      } else {
        this.showPopup = false
      }
    },

    generarDatosMaptip () {
      let layerId = this.rawPopupData.layer
      let attributes = this.rawPopupData.attributes

      let props = {
        x: this.rawPopupData.position.x,
        y: this.rawPopupData.position.y
      }
      let attrs = []
      let buttons = []

      if (layerId === 'layer-medios') {
        if (!attributes.notShowMaptip) {
          // Obtenemos los medios
          let medios = this.$store.getters['medio/medios']

          // Filtramos los medios que tengan dispositivos asociados, con el medio clicado en el mapa (popup)
          // let medioDispositivos = medios.filter(x => x.MEDIO === attributes.MEDIO && x.MEDIO_DISPOSITIVOS)
          let medio = medios.find(x => x.ID_MEDIO === attributes.ID_MEDIO)
          if (medio) {
            let dispositivos = medio.MEDIO_DISPOSITIVOS

            let medioDispositivosAsociados = dispositivos.map(x => x.PROVEEDOR + ' - ' + x.CODIGO_EXTERNO)

            props.titulo = attributes.MEDIO

            let listaDispositivosTxt = ''
            for (let i = 0; i < medioDispositivosAsociados.length; i++) {
              listaDispositivosTxt += medioDispositivosAsociados[i]
              if (i < medioDispositivosAsociados.length - 1) {
                listaDispositivosTxt += ', '
              }
            }
            attrs = [
              // {name: 'Localidad', value: 'servicio????'},
              { name: 'Nombre', value: attributes.NOMBRE },
              { name: 'Tel. interno', value: attributes.TELEFONO_INTERNO },
              { name: 'Tel. externo', value: attributes.TELEFONO_EXTERNO },
              { name: 'Zona', value: attributes.ZONA },
              { name: 'Guardias', value: attributes.GUARDIAS },
              { name: 'Fecha ult. señal', value: this.$date.formatDate(attributes.FECHA, 'DD/MM/YYYY HH:mm') },
              { name: 'Tipo', value: attributes.TIPO },
              { name: 'Categoría', value: attributes.CATEGORIA },
              { name: 'Hora entrada', value: attributes.HORA_ENTRADA },
              { name: 'Hora salida', value: attributes.HORA_SALIDA },
              { name: 'Proveedor', value: attributes.PROVEEDOR },
              { name: 'Dispositivo/s', value: listaDispositivosTxt.length > 0 ? listaDispositivosTxt : '-' }
            ]
          }

          /**
           * Distinguir entre dispositivo o medio
           */
          // TODO: Aqui habrá problemas al cambiar de pintar 1 medio a pintar todos los dispositivos
          let trackActivo = this.tracksActivos.indexOf(this.attributes.ID_DISPOSITIVO) > -1

          buttons = [
            // {id: 'zoom', icon: 'zoom_in', label: 'Zoom medio', visible: true},
            { id: 'showTrack', icon: 'mdi-map-marker', label: 'Mostrar ruta', visible: !trackActivo },
            { id: 'showTrackFechas', icon: 'mdi-calendar', label: 'Mostrar ruta entre fechas', visible: !trackActivo },
            { id: 'hideTrack', icon: 'mdi-map-marker-off', label: 'Ocultar ruta', visible: trackActivo },
            { id: 'exportTrack', icon: 'mdi-download', label: 'Descargar SHP y KML', visible: trackActivo }
            // {id: 'call', icon: 'video_call', label: 'Compartir video', visible: this.medioLogged}
          ]
        }
      } else if (layerId === 'layer-infraestructuras') {
        props.titulo = attributes.NOMBRE
        attrs = [
          { name: 'Tipo', value: attributes.TIPO_BASE },
          { name: 'Zona', value: attributes.ZONA },
          { name: 'ETRS89 H30N X', value: attributes.X },
          { name: 'ETRS89 H30N Y', value: attributes.Y }
        ]
      } else if (layerId === 'layer-track') {
        props.titulo = 'Track ' + attributes.MEDIO

        let velocidad = 0
        if (attributes.VELOCIDAD) {
          velocidad = attributes.VELOCIDAD
        }

        attrs = [
          { name: 'Fecha', value: this.$date.formatDate(attributes.FECHA, 'DD/MM/YYYY HH:mm') },
          { name: 'Velocidad', value: velocidad + ' km/h' }
        ]
      } else if (layerId === 'layer-incendios') {
        const dateFormat = 'DD/MM/YYYY HH:mm'
        props.titulo = attributes.LOCALIDAD
        attrs = [
          { name: 'Municipio', value: attributes.MUNICIPIO },
          { name: 'Localidad', value: attributes.LOCALIDAD },
          { name: 'Tipo detección', value: this.$store.getters['incendio/getTipoDeteccionById'](attributes.ID_TIPO_DETECCION).DETECCION },
          { name: 'Fecha detección', value: attributes.FECHA_DETECCION },
          { name: 'Nivel declarado', value: attributes.NIVEL ? attributes.NIVEL.TIPO_INCENDIO_NIVEL.INCENDIO_NIVEL : null },
          { name: 'Fecha declaración nivel', value: attributes.NIVEL ? this.$date.parseDate(attributes.NIVEL.FECHA_INICIO).local().format(dateFormat) : null },
          { name: 'Fecha desactivación nivel', value: attributes.NIVEL ? this.$date.parseDate(attributes.NIVEL.FECHA_FIN).local().format(dateFormat) : null },
          { name: 'Estado', value: attributes.ESTADOS.TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO },
          { name: 'Fecha declaración estado', value: this.$date.parseDate(attributes.ESTADOS.FECHA).local().format(dateFormat) },
          { name: 'Nº Medios terrestres', value: attributes.MEDIOS.filter(medio => medio.CATEGORIA === 'Terrestre').length.toString(), icon: 'mdi-car' },
          { name: 'Nº Medios aéreos', value: attributes.MEDIOS.filter(medio => medio.CATEGORIA === 'Aéreo').length.toString(), icon: 'mdi-airplane' },
          { name: 'Nº Recursos', value: attributes.MEDIOS.filter(medio => medio.CATEGORIA === 'Recurso').length.toString(), icon: 'mdi-account' },
          { name: 'Observaciones', value: attributes.OBSERVACIONES }
        ]

        buttons = [
          { id: 'showAllInfoDialog', icon: 'mdi-eye', label: 'Mostar información', visible: true }
          // { id: 'ReporteInformacion', icon: 'mdi-file-pdf-box', label: 'PDF Información incendio', idIncendio: attributes.ID_INCENDIO, visible: true }
          // {id: 'zoom', icon: 'zoom_in', tooltip: 'Zoom incendio', show: true}
        ]
      } else if (['layer-avion', 'layer-bomberos', 'layer-BRIF', 'layer-central-comunicaciones', 'layer-helicoptero', /* 'layer-pick-up', */ 'layer-repetidor-comunicaciones', /* 'layer-reten', */ 'layer-SOS', 'layer-torreta-vigilancia'].includes(layerId)) {
        props.titulo = attributes.TIPO

        attrs = [
          { name: 'Identificador', value: attributes.DENOMINAC },
          { name: 'Municipio', value: attributes.MUNICIPIO },
          { name: 'Altitud ', value: attributes.ALTITUD }
        ]
      } else if (layerId === 'layer-reten') {
        props.titulo = 'Base retén'

        attrs = [
          { name: 'Nombre', value: attributes.Nombre }
        ]
      } else if (layerId === 'layer-autobomba') {
        props.titulo = 'Base autobomba'

        attrs = [
          { name: 'Nombre', value: attributes.Nombre }
        ]
      } else if (layerId === 'layer-punto-encuentro') {
        props.titulo = 'Puntos de encuentro'

        attrs = [
          { name: 'Identificador', value: attributes.PUNTO },
          { name: 'Nombre', value: attributes.NOMBRE },
          { name: 'Municipio', value: attributes.MUNICIPIO },
          { name: 'Ubicación', value: attributes.UBICACION }
        ]
      } else if (layerId === 'layer-punto-agua-aer' || layerId === 'layer-punto-agua-ter') {
        props.titulo = 'Puntos de agua'

        attrs = [
          { name: 'Identificador', value: attributes.PUNTO },
          { name: 'Demarcación', value: attributes.DEMARCACIO },
          { name: 'Municipio', value: attributes.MUNICIPIO },
          { name: 'Núcleo de población', value: attributes.NUC_URB },
          { name: 'Paraje', value: attributes.PARAJE },
          { name: 'Superficie', value: attributes.SUPERFICIE },
          { name: 'Tipo', value: attributes.TIPO },
          { name: 'Estacional', value: attributes.ESTACIONAL },
          { name: 'Capacidad', value: attributes.CAPACIDAD },
          { name: 'Medios aéreos', value: attributes.MED_AEREO },
          { name: 'Altitud', value: attributes.ALTITUD },
          { name: 'Observaciones', value: attributes.OBSERVAC }
        ]
      } else if (layerId === 'layer-estaciones-meteorologicas') {
        props.titulo = attributes.DENOMINACI

        attrs = [
          { name: 'Tipo ', value: attributes.TIPO },
          { name: 'Denominación:', value: attributes.DENOMINACI }
        ]
      } else if (layerId === 'layer-montes-utilidad') {
        props.titulo = 'Montes utilidad pública'

        attrs = [
          { name: 'Identificador', value: attributes.id },
          { name: 'Nombre ', value: attributes.Nombre },
          { name: 'Código', value: attributes.Código_de },
          { name: 'Brigada', value: attributes.Brigada },
          { name: 'Comarca', value: attributes.Comarca },
          { name: 'Partido judicialicial', value: attributes.Partido_jud },
          { name: 'Municipio', value: attributes.Municipio },
          { name: 'Titular', value: attributes.Titular },
          { name: 'Superficie', value: attributes.Superficie }
        ]
      } else if (layerId === 'layer-plan-operaciones') {
        props.titulo = 'Plan de operaciones'

        attrs = []
        if (attributes.LONGITUD) {
          attrs.push({ name: 'Longitud', value: (Math.round(((attributes.LONGITUD / 1000) * 100)) / 100) + ' km' })
        }

        if (attributes.AREA) {
          attrs.push({ name: 'Área', value: (Math.round(((attributes.AREA / 10000) * 100)) / 100) + ' ha' })
        }
      } else if (layerId === 'layer-emergencias') {
        props.titulo = 'Emergencia'

        attrs = [
          { name: 'Medio', value: attributes.MEDIO },
          { name: 'Fecha', value: attributes.FECHA }
        ]
      } else if (layerId === 'layer-infraestructuras') {
        props.titulo = attributes.NOMBRE
        attrs = [
          { name: 'Tipo', value: attributes.TIPO },
          { name: 'Zona', value: attributes.ZONA },
          { name: 'ETRS89 H30N X', value: attributes.X },
          { name: 'ETRS89 H30N Y', value: attributes.Y }
        ]
      } else if (layerId === 'layer-infraestructuras-propias') {
        props.titulo = attributes.NOMBRE
        attrs = [
          { name: 'Tipo', value: attributes.TIPO },
          { name: 'Categoria', value: attributes.CATEGORIA },
          { name: 'Codigo', value: attributes.CODIGO },
          { name: 'Municipio', value: attributes.MUNICIPIO },
          { name: 'Provincia', value: attributes.PROVINCIA },
          { name: 'Responsable', value: attributes.RESPONSABLE },
          { name: 'Fecha alta', value: this.$date.formatDate(attributes.FECHA_ALTA, 'DD/MM/YYYY HH:mm') },
          { name: 'Fecha baja', value: this.$date.formatDate(attributes.FECHA_BAJA, 'DD/MM/YYYY HH:mm') },
          { name: 'Descripcion', value: attributes.DESCRIPCION },
          { name: 'Direccion', value: attributes.DIRECION }
        ]
      } else if (layerId === 'layer-perimetros') {
        props.titulo = 'Perímetro'
        attrs = [
          { name: 'Área (ha)', value: attributes.AREA },
          { name: 'Perímetro (km)', value: attributes.PERIMETRO }
        ]
      }

      this.datosPopup = {
        properties: props,
        attributes: attrs,
        buttons: buttons
      }

      /* if (!this.showIncendiosCards) {
        this.datosPopup.properties.x += 500
      } */
    },

    clickBtn (btn) {
      if (btn === 'zoom') {
        this.zoom()
      } else if (btn === 'showTrack') {
        this.loadTrack()
      } else if (btn === 'hideTrack') {
        this.hideTrack()
      } else if (btn === 'exportTrack') {
        this.exportTrack()
      } else if (btn === 'showTrackFechas') {
        this.showFechasDialog = true
      } else if (btn === 'call') {
        this.callVideo()
      } else if (btn === 'ReporteInformacion') {
        this.downloadPDF(this.$store.getters['incendio/getIncendioByID'](this.datosPopup.buttons[0].idIncendio))
      } else if (btn === 'showAllInfoDialog') {
        const separator = { name: 'separator', value: null }
        const separatorIndexFechaDet = this.datosPopup.attributes.findIndex(x => x.name === 'Fecha detección') + 1
        const separatorIndexFechaDecEst = this.datosPopup.attributes.findIndex(x => x.name === 'Fecha declaración estado') + 1
        let copyAtrs = [...this.datosPopup.attributes]
        copyAtrs.splice(separatorIndexFechaDet, 0, separator)
        copyAtrs.splice(separatorIndexFechaDecEst, 0, separator)
        this.openAllInfoDialog(copyAtrs)
      }

      this.showPopup = false
    },

    openAllInfoDialog (attrs) {
      this.infoAttrs = attrs
      this.showAllInfoDialog = true
    },

    zoom () {
      let center = {
        target: [this.attributes.LONGITUDE, this.attributes.LATITUDE],
        zoom: 15
      }

      ArcGIS.setCenterMap(center)
    },

    downloadPDF (incendio) {
      PDFHelper.pdfReporteInformacion(incendio)
    },

    async loadTrack () {
      let timeBack = constants.track.timeBackHours

      this.isLoadingTrack = true

      try {
        /**
         * Distinguir entre dispositivo o medio
         */
        let response = await api.medio.getTrackMedio(this.attributes.ID_DISPOSITIVO, timeBack)

        if (response.status === 200) { // TODO: reemplazar por 204 si no devuelve nada
          if (response.data.length >= 2) {
            this.showTrack(response.data)
          } else {
            this.msgSnackBar = 'El medio no dispone de track.'
            this.showSnackBar = true
          }
        }
      } catch (err) {
        this.$log.error('Error al obtener el track', err)
        this.msgSnackBar = 'Error al obtener el track.'
      } finally {
        this.isLoadingTrack = false
      }
    },

    async loadTrackFechas (data) {
      this.isLoadingTrack = true

      this.showFechasDialog = false

      let fechaInit = data.fechaInit
      let fechaFin = data.fechaFin

      try {
        /**
         * Distinguir entre dispositivo o medio
         */
        // let response = this.attributes.ID_DISPOSITIVO
        //   ? await api.medio.getTrackFechasMedio(this.attributes.ID_DISPOSITIVO, fechaInit, fechaFin)
        //   : await api.medio.getTrackFechasMedio(this.medio.ID_MEDIO, fechaInit, fechaFin)

        let response = await api.medio.getTrackFechasMedio(this.attributes.ID_DISPOSITIVO, fechaInit, fechaFin)

        if (response.status === 200) {
          if (response.data.length >= 2) {
            this.showTrack(response.data)
          } else {
            this.msgSnackBar = 'El medio no dispone de track.'
            this.showSnackBar = true
          }
        }

        if (response.status === 204) {
          this.msgSnackBar = 'El medio no dispone de track.'
          this.showSnackBar = true
        }
      } catch (err) {
        this.$log.error('Error al obtener el track', err)
        this.msgSnackBar = 'Error al obtener el track.'
      } finally {
        this.isLoadingTrack = false
      }
    },

    showTrack (posiciones) {
      let maxTimeDiference = constants.track.timeDiferenceTrackMinutes
      let maxPositions = constants.track.maxPositions

      // this.track.ID_MEDIO = posiciones[0].ID_MEDIO
      // this.track.MEDIO = posiciones[0].MEDIO
      // this.track.positions = []

      let positions = []
      for (let i = 0; i < posiciones.length; i++) {
        if (i === 0) {
          // this.track.positions.push(posiciones[i])
          positions.push(posiciones[i])
        } else {
          let dateLastPos = this.$date.parseDate(posiciones[i - 1].FECHA)
          let date = this.$date.parseDate(posiciones[i].FECHA)
          let difMinutes = dateLastPos.diff(date, 'minutes')

          if (difMinutes <= maxTimeDiference && positions.length <= maxPositions) { // this.track.positions
            // this.track.positions.push(posiciones[i])
            positions.push(posiciones[i])
          } else {
            continue
          }
        }
      }

      /**
       * Distinguir entre dispositivo o medio
       */
      let idTrack = this.attributes.ID_DISPOSITIVO
      let nameTrack = this.medio.MEDIO + '-' + this.attributes.PROVEEDOR

      ArcGIS.drawTrack(idTrack, nameTrack, positions, true)
      this.tracksActivos.push(idTrack)
      // this.setExtentTrack(this.track.positions)
      // this.$emit('drawTrack', this.medio.ID_MEDIO, positions) // this.track.positions
      // ArcGIS.drawTrack(this.medio.ID_MEDIO, this.medio.MEDIO, positions)
      // this.tracksActivos.push(this.medio.ID_MEDIO)
    },

    setExtentTrack (positions) {
      let latitud = []
      let longitud = []
      let extent = {}

      for (let i = 0; i < positions.length; i++) {
        latitud.push(positions[i].LATITUD)
        longitud.push(positions[i].LONGITUD)
      }
      extent.xmax = Math.max(...longitud)
      extent.xmin = Math.min(...longitud)
      extent.ymax = Math.max(...latitud)
      extent.ymin = Math.min(...latitud)
      extent.wkid = 4326
      this.$emit('setExtentMap', extent)
    },

    hideTrack () {
      /**
       * Distinguir entre dispositivo o medio
       */
      let index = this.attributes.ID_DISPOSITIVO
        ? this.tracksActivos.indexOf(this.attributes.ID_DISPOSITIVO)
        : this.tracksActivos.indexOf(this.medio.ID_MEDIO)

      if (index > -1) {
        this.tracksActivos.splice(index, 1)
      }
      /**
       * Ocultar dispositivo o medio
       */
      this.attributes.ID_DISPOSITIVO
        ? ArcGIS.hideTrack(this.attributes.ID_DISPOSITIVO)
        : ArcGIS.hideTrack(this.medio.ID_MEDIO)
    },

    exportTrack () {
      /**
       * Distinguir entre dispositivo o medio
       */
      this.attributes.ID_DISPOSITIVO
        ? ArcGIS.exportTrack({ idMedio: this.attributes.ID_DISPOSITIVO, medio: this.attributes.CODIGO_EXTERNO })
        : ArcGIS.exportTrack({ idMedio: this.medio.ID_MEDIO, medio: this.medio.MEDIO })
    },

    callVideo () {
      this.$store.dispatch('requestSharedCamera', this.medioLogged)
    }
  },

  mounted () {
    this.$eventHub.$on('showPopup', this.onShowPopup)
  }
}
</script>

<style scoped>

</style>
